import React, { useEffect } from 'react';

import type { AppProps } from 'next/app';
import Head from 'next/head';
import { withRouter } from 'next/router';
import TagManager from 'react-gtm-module';
import { ThemeProvider } from 'styled-components';

import GlobalStyles from '@/styles/global';
import theme from '@/styles/theme';

const App = ({ Component, pageProps }: AppProps) => {
    useEffect(() => {
        TagManager.initialize({ gtmId: 'GTM-WLZ8WXB' });
    }, []);

    return (
        <>
            <GlobalStyles />
            <Head>
                <title>Go City</title>
                <meta name="description" content="Go City" />
                <meta property="og:title" content="GoCity.com" key="title" />
                <meta name="theme-color" content="#28939C" />
                <meta
                    name="viewport"
                    content="width=device-width, initial-scale=1.0, viewport-fit=cover, minimum-scale=1"
                />
            </Head>
            <ThemeProvider theme={theme}>
                <Component {...pageProps} />
            </ThemeProvider>
        </>
    );
};

export default withRouter(App);
