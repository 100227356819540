import { SamplingContext } from '@sentry/types';

const defaultTraceSampleRate = parseFloat(process.env.SENTRY_TRACE_SAMPLE_RATE ?? '0.01');

export const tracesSampler = (samplingContext: SamplingContext) => {
    if (samplingContext.transactionContext.name === 'GET /api/health-check') {
        return 0;
    } else {
        return defaultTraceSampleRate;
    }
};
