export type ThemeType = typeof theme;

const theme = {
    font: {
        inter: 'Inter, sans-serif',
        urbanist: 'Urbanist, sans-serif',
    },
    colours: {
        surface: {
            default: '#ffffff',
            secondary: '#f1f3f7',
            brandHighlight: '#28939c',
            deepBlue: '#002a3a',
            premium: '#ac4fc6',
        },
        primary: {
            darkTeal: '#28939c',
            lightTeal: '#2ad2c9',
            shade: {
                darkTeal: '#00656e',
                lightTeal: '#00a098',
            },
            tint: {
                darkTeal: '#62c4cd',
                lightTeal: '#70fffc',
            },
        },
        secondary: {
            chartreuse: '#dbe442',
            alertRed: '#e81e32',
            premium: '#ac4fc6',
            deepBlue: '#002a3a',
            deepBlue90: '#1a3f4e',
            deepBlue50: '#80959d',
        },
        text: {
            default: '#002a3a',
            lighter: '#4f4e66',
            label: '#6e7191',
            placeholder: '#a0a3bd',
            dark: {
                default: '#ffffff',
                lighter: '#ffffff',
            },
        },
        alert: {
            default: '#dbe442',
        },
    },
    breakpoints: {
        mobile: '600px',
        tablet: '970px',
        desktop: '1280px',
    },
};

export default theme;
