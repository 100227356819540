import Link from 'next/link';
import styled, { createGlobalStyle, css } from 'styled-components';
import { normalize } from 'styled-normalize';

import { IAspectRatio } from '@/interfaces/pages';

import theme from './theme';

const GlobalStyle = createGlobalStyle`
    ${normalize}

    * {
        box-sizing: border-box;
    }

    *, html, body {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

    html {
        font-synthesis: none;
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
        text-size-adjust: 100%;
        -webkit-tap-highlight-color: transparent;
        --scroll-behavior: smooth!important;
        scroll-behavior: smooth;
    }

    body {
        margin: 0;
        font-family: ${theme.font.inter};
        transition: all 0.4s ease-in;
        font-size: 16px;

        &.light-blue {
            background-color: ${theme.colours.surface.secondary};
            color: ${theme.colours.text.default};
        }

        &.dark-blue {
            background-color: ${theme.colours.surface.deepBlue};
            color: ${theme.colours.surface.default};
        }

        &.dark-teal {
            background-color: ${theme.colours.primary.darkTeal};
            color: ${theme.colours.surface.default};
        }

        &.white {
            background-color: ${theme.colours.surface.default};
            color: ${theme.colours.text.default}
        }

        &.light-teal {
            background-color: ${theme.colours.primary.lightTeal};
            color: ${theme.colours.text.default}
        }

        &.purple {
            background-color: ${theme.colours.secondary.premium};
            color: ${theme.colours.surface.default};
        }

        &.red {
            background-color: ${theme.colours.secondary.alertRed};
            color: ${theme.colours.surface.default};
        }

        &.green {
            background-color: ${theme.colours.secondary.chartreuse};
            color: ${theme.colours.text.default}
        }

        &.no-scroll {
          overflow: hidden;
        }
    }

    h1,h2,h3,h4,h5,h6 {
      margin:0;
    }

    h1,h2,h3 {
      font-family: ${theme.font.urbanist};
    }

    h1 {
        font-size: 2.813rem;
        line-height: 3.125rem;
    }

    h2 {
        font-size: 2.5rem;
        line-height: 2.81rem;
        margin: 2rem 0 0.94rem;
    }

    h3 {
        font-size: 1.75rem;
        line-height: 2.25rem;
    }

    h4 {
        font-size: 1.75rem;
        line-height: 2.25rem;
    }

    h5 {
        font-size: 1.5rem;
        line-height: 1.875rem;
        font-weight: 600;
    }

    p, ul, ol {
        font-size: 1.313rem;
        line-height: 1.75rem;
    }

    li {
      margin-bottom: 1rem;
    }

    img {
        max-width: 100%;
        height: auto;
        display: block;
    }

    .fadeInUp {
        opacity: 0;
        transform: translateY(5rem);
        transition: all 0.5s ease-in;

        &.iv {
            opacity: 1;
            transform: translateY(0);
        }
    }

    .embla {
      overflow: hidden;
    }

    .embla__container {
      display: flex;
    }

    .embla__slide {
      flex: 0 0 100%;
      min-width: 0;
    }

    //Desktop
    @media (min-width: ${theme.breakpoints.mobile}) {
        h1 {
          font-size: 4.375rem;
          line-height: 4.75rem;
        }

        h2 {
          font-size: 3.125rem;
          line-height: 3.44rem;
          margin: 2rem 0 0.94rem;
        }

        h3 {
          font-size: 1.75rem;
          line-height: 2.125rem;
        }

        p, ul, ol {
          font-size: 1.313rem;
          line-height: 1.75rem;
        }
    }
`;

export const AspectRatio = styled.div<{ ratio: IAspectRatio; rounded?: boolean }>`
    position: relative;
    padding-bottom: ${({ ratio: ar }) => (ar === '16:9' && '55.33%') || '100%'};
    height: 0;
    overflow: hidden;
    border-radius: ${({ rounded }) => (rounded && '0.75rem') || '0'};
    video,
    img {
        object-fit: cover;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
    }
`;

export const Button = styled(Link)<{ arrow?: string; color?: 'light' | 'dark' | 'blue' | 'white'; size?: string }>`
    background-color: ${theme.colours.surface.deepBlue};
    color: ${({ color }) => (color === 'dark' && theme.colours.surface.deepBlue) || 'white'};
    border-radius: 0.75rem;
    padding: 0.75rem 1.43rem;
    display: inline-block;
    text-decoration: none;
    font-weight: 600;
    transition: background-color 0.3s ease-in;
    position: relative;

    &:hover {
        background-color: ${theme.colours.primary.shade.darkTeal};
    }

    ${({ arrow }) =>
        arrow &&
        css`
            padding: 0.75rem 2.9rem 0.75rem 1.43rem;

            &::after {
                content: '';
                position: absolute;
                width: 18px;
                height: 16px;
                top: 50%;
                right: 16px;
                transform: translate(0, -50%);
                transition: transform 0.3s ease-in;
                background-repeat: none;
                background-position: center;
                background-image: url('/assets/img/icons/arrow-light.svg');
            }

            &:hover::after {
                transform: translate(4px, -50%);
            }
        `}
    ${({ color }) =>
        color === 'dark' &&
        css`
            background-color: ${theme.colours.primary.lightTeal};

            &:hover {
                background-color: ${theme.colours.primary.shade.lightTeal};
            }
            &::after {
                width: 16px;
                background-image: url('/assets/img/icons/arrow-dark.svg');
            }
        `}

    ${({ color }) =>
        color === 'blue' &&
        css`
            background-color: ${theme.colours.surface.deepBlue};

            &:hover {
                background-color: ${theme.colours.surface.deepBlue};
            }
            &::after {
                width: 16px;
                background-image: url('/assets/img/icons/arrow-light.svg');
            }
        `}

    ${({ color }) =>
        color === 'white' &&
        css`
            background-color: ${theme.colours.surface.default};
            color: ${theme.colours.surface.deepBlue};

            &:hover {
                background-color: ${theme.colours.surface.default};
            }
            &::after {
                width: 16px;
                background-image: url('/assets/img/icons/arrow-dark.svg');
            }
        `}

    ${({ size }) =>
        size === 'small' &&
        css`
            padding: 0.5rem 1rem 0.5rem;
            font-size: 1rem;

            &::after {
                right: 10px;
            }
        `}
`;

export const CircleArrow = styled.div<{ alignment?: string; variant?: string }>`
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    background-color: ${({ variant }) => (variant === 'white' ? 'white' : theme.colours.secondary.chartreuse)};
    display: flex;
    align-items: center;
    justify-content: center;

    &::after {
        content: '';
        position: relative;
        display: block;
        width: 15px;
        height: 15px;
        background-image: url('/assets/img/icons/arrow-dark.svg');
    }

    ${({ alignment }) =>
        alignment === 'left' &&
        css`
            &::after {
                transform: rotate(-180deg);
            }
        `}

    ${({ variant }) =>
        variant === 'dark' &&
        css`
            background-color: ${theme.colours.surface.deepBlue};
            &::after {
                width: 17px;
                height: 15px;
                background-image: url('/assets/img/icons/arrow-light.svg');
            }
        `}

      ${({ variant }) =>
        variant === 'brand' &&
        css`
            background-color: ${theme.colours.surface.brandHighlight};
            &::after {
                width: 17px;
                height: 15px;
                background-image: url('/assets/img/icons/arrow-light.svg');
            }
        `}
`;

export const Container = styled.div<{ size?: string; padding?: boolean }>`
    width: 100%;
    max-width: 80rem;
    margin: 0 auto;
    padding-left: ${({ padding }) => (padding === false && '0') || '1.25rem'};
    padding-right: ${({ padding }) => (padding === false && '0') || '1.25rem'};
    max-width: ${({ size }) =>
        (size === 'form' && '40rem') ||
        (size === 'narrow' && '54rem') ||
        (size === 'medium' && '60.87rem') ||
        (size === 'wide' && '88rem') ||
        '80rem'};

    @media (min-width: ${theme.breakpoints.tablet}) {
        padding-left: 2rem;
        padding-right: 2rem;
    }
    @media (min-width: ${theme.breakpoints.desktop}) {
        padding-left: 2.5rem;
        padding-right: 2.5rem;
    }
`;

export const Section = styled.section<{ spacing?: string }>`
    margin: ${({ spacing }) => (spacing === 'small' && '3rem 0') || '5rem 0'};
    overflow: hidden;

    @media (min-width: ${theme.breakpoints.tablet}) {
        margin: ${({ spacing }) => (spacing === 'small' && '4.5rem 0') || '7.5rem 0'};
    }
`;

export const Flex = styled.div<{ justify: string; align: string }>`
    display: flex;
    justify-content: ${({ justify }) => justify};
    align-items: ${({ align }) => align};
`;

export const SliderControls = styled.div`
    display: flex;
    justify-content: center;
    margin: 0 0 1.875rem;
    gap: 1.875rem;

    @media (min-width: ${theme.breakpoints.tablet}) {
        margin: 0 0 3rem;
    }
`;

export const SliderButton = styled.button<{ variant: 'next' | 'prev' | 'pause' | 'play'; colour?: string }>`
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border: none;
    background-color: ${({ colour }) =>
        colour === 'green' ? theme.colours.secondary.chartreuse : theme.colours.surface.default};
    cursor: pointer;
    transition: background-color 0.3s ease-in;

    &:hover {
        background-color: ${({ colour }) =>
            colour === 'green' ? theme.colours.secondary.chartreuse : theme.colours.surface.brandHighlight};
    }

    &::after {
        content: '';
        position: relative;
        display: block;
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center;
    }

    ${({ variant }) =>
        variant === 'next' &&
        css`
            &::after {
                width: 16px;
                height: 16px;
                background-image: url('/assets/img/icons/arrow-dark.svg');
            }
        `}

    ${({ variant }) =>
        variant === 'prev' &&
        css`
            &::after {
                width: 16px;
                height: 16px;
                background-image: url('/assets/img/icons/arrow-dark.svg');
                transform: rotate(180deg);
            }
        `}

    ${({ variant }) =>
        variant === 'pause' &&
        css`
            &::after {
                width: 10px;
                height: 14px;
                background-image: url('/assets/img/icons/pause-dark.svg');
                transform: rotate(180deg);
            }
        `}

    ${({ colour }) =>
        colour === 'blue' &&
        css`
            background-color: ${theme.colours.surface.deepBlue};
            &::after {
                width: 16px;
                height: 16px;
                background-image: url('/assets/img/icons/arrow-light.svg');
            }
        `}
`;

export const TextInputSmall = styled.input`
    border-radius: 0.5rem;
    border: none;
    padding: 0.5rem 2.5rem 0.5rem 1rem;
    cursor: pointer;
    color: ${theme.colours.text.default};
    border: 1px solid ${theme.colours.surface.brandHighlight};
    background-color: ${theme.colours.surface.secondary};
    font-size: 0.875rem;
    max-width: 9.68rem;
    &::-webkit-input-placeholder {
        color: ${theme.colours.text.default};
        opacity: 1;
    }
`;

export default GlobalStyle;
